import {createContext, useContext, useState} from "react";

const ErrorContext = createContext();

export const ErrorContextProvider = ({children}) => {
    const [error, setError] = useState({place: '', message: ''});

    return (
        <ErrorContext.Provider value={{error, setError}}>
            {children}
        </ErrorContext.Provider>
    );
}

export const useErrorContext = () => {
    return useContext(ErrorContext)
}