import {React} from '../../imports/imports-packege';
import HeaderStyle from './HeaderStyle.module.scss';
import {MainLogo, TelegramIcon} from '../../imports/imports-images'
import LanguagesSelect from './LanguagesSelect';

const Header = () => {

    return (
        <div className={HeaderStyle.container}>
            <div className={HeaderStyle.LogoNavBlock}>
                <div className={HeaderStyle.LogoContainer}>
                    {/* <img src={MainLogo} alt="" className={HeaderStyle.LogoImage}/> */}
                </div>
            </div>
            <div className={HeaderStyle.RightColum}>
                {/* <div className={HeaderStyle.TelegramButton}> */}
                    {/* <a href="">
                        Telegram
                        <img src={TelegramIcon} alt=""/>
                    </a> */}
                {/* </div> */}
                {/* <LanguagesSelect /> */}
            </div>

        </div>
    );
};

export default Header;