import {SendIcon} from '../../imports/imports-images';
import {axios, queryString, React, useEffect, useState, useTranslation} from "../../imports/imports-packege";
import {useData} from "../Context/ContextApp";
import PreviewStyle from "./PreviewStyle.module.scss";
import PWAPreview from "./PWAPreview/PWApreview";
import Switcher from '../UI/Switcher/Switcher';
import {useErrorContext} from "../ErrorHandle/ErrorContext";
import {validateData} from "../../util/validation";
import Loader from '../UI/Loader/Loader';

const Preview = () => {
    const {t} = useTranslation();
    const {data, updateData} = useData();
    const {setError} = useErrorContext();
    const [isLoading, setIsLoading] = useState(false);
    const [landName, setLandName] = useState('');
    const [keitaroId, setKeitaroId] = useState('');
    const [builderMode, setBuilderMode] = useState('create');
    const [buttonText, setButtonText] = useState('Send');

    const handleLandName = (name) => setLandName(name);
    useEffect(() => {
        handleLandName(data.landName);
    }, [data.landName]);


    const handleButtonText = (text) => setButtonText(text);
    const handlerKeitaroId = (query_keitaroId) => setKeitaroId(query_keitaroId);
    const handleBuilderMode = (mode) => setBuilderMode(mode);

    const [landingType, setLandingType] = useState('');
    const handleLandingType = (type_land) => setLandingType(type_land);

    const currentUrl = window.location.href;
    const parsedParams = queryString.parseUrl(currentUrl);
    const keitaro_id = parsedParams.query.id_land;
    const type_landing = parsedParams.query.type_land;
    const builder_mode = parsedParams.query.mode;

    const [responseMessage, setResponseMessage] = useState(undefined);
    const handleResponseMessage = (message) => setResponseMessage(message)
    useEffect(() => {
        if (responseMessage === 'success') {
            alert('Успішно завантажено на сервер')
        } 
        if (responseMessage === 'error') {
            alert('Виникла помилка, зверніться до розробників')
        }
    }, [handleResponseMessage]);

    useEffect(() => {
        handlerKeitaroId(!keitaro_id ? data.keitaroId : keitaro_id);
        handleBuilderMode(builder_mode);
        handleLandingType(type_landing);
        updateData({
            ...data,
            builderMode: builderMode,
            keitaroId: keitaroId,
            landingType: landingType
        })
    }, [keitaroId, type_landing, builder_mode]);

    var dataSend = {};
    useEffect(() => {
        dataSend = {
            pwaDesign: data.pwaDesign,
            userPixel: data.userPixel,
            userToken: data.userToken,
            userDomain: data.userDomain,
            userOfferUrl: data.userOfferUrl,
            landingType: data.landingType,
            builderMode: data.builderMode,
            languageBilder: data.languageBilder,
            keitaroId: data.keitaroId,
            oneSignalId: data.oneSignalId,
            appName: data.appName,
            langCode: data.langCode,
            iconUrl: data.iconUrl,
            countComments: data.countComments,
            screenshotImages: data.screenshotImages,
            author: data.author,
            categories: data.categories,
            age: data.age,
            appSize: data.appSize,
            amountInstall: data.amountInstall,
            buttonText: data.buttonText,
            landDescriptionTitle: data.landDescriptionTitle,
            landDescriptionText: data.landDescriptionText,
            multiCategories: data.multiCategories,
            recommendedApp: data.recommendedApp,
            reviews_data: data.reviews_data,
            parseContents: data.parseContents,
            progressBars: data.progressBars,
            appRatingPage: data.appRatingPage
        }
    }, [data, builder_mode, dataSend]);

    useEffect(() => {
        console.log(data, 'data');
    }, [data]);

    function SendDataPwa() {
        const sendData = async () => {
            try {
                setIsLoading(true);
                const {place, message} = validateData(dataSend);

                if (message) {
                    setIsLoading(false);
                    setError({place, message});
                    return;
                }

                setError({place: '', message: ''});

                // const url = 'http://localhost:8181/api/create-pwa';
                const url = 'https://pwagr.tech/api/create-pwa';
                const response = await axios.post(url, dataSend);
                handleResponseMessage(response.data);
            } catch (error) {
                console.log('Error:', error);
            } finally {
                handleButtonText('Готово');
            }
        };
        sendData();
    }

    return (
        <div className={PreviewStyle.PreviewContainer}>
            <div className={PreviewStyle.HeaderPreview}>
                <div className={PreviewStyle.InfoDownload}>
                    {isLoading ? (
                        <Loader />
                    ): (
                        <div className={PreviewStyle.InfoDownload_SendLand} onClick={SendDataPwa}>
                            <div className={PreviewStyle.InfoDownload_SendLand_Button}>
                                <img src={SendIcon} className={PreviewStyle.IconSend} alt=""/>
                            </div>
                        </div>
                    )}
                    <p className={PreviewStyle.TitleSwitcher}>{t('common.appStyle')}</p>
                    <div className={PreviewStyle.SwitchDesignPreview}>

                        <Switcher/>
                    </div>
                </div>
            </div>
            <PWAPreview/>
        </div>
    );
};

export default Preview;