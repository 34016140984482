import {React, BrowserRouter, StatePool, useEffect, axios} from '../../imports/imports-packege';
import MainContentSectionStyle from './MainContentSectionStyle.module.scss';
import {Navigation, Preview} from "../../imports/imports-components";
import NavigationStyle from "../Navigation/NavigationStyle.module.scss";
import EditContentTabs from "../Navigation/EditContentTabs";
import {useData} from "../Context/ContextApp";


const ActiveTab = StatePool.createState('');
const MainContentSection = () => {

    const { data, updateData } = useData();
    let getUserQuery = new URLSearchParams(window.location.search);
    let getQueryTypeCreate = getUserQuery.get('mode');
    let getQueryKeitaroId = getUserQuery.get('id_land');
    let getQueryUserPixel = getUserQuery.get('pixel');
    let getQueryUserToken = getUserQuery.get('token');
    let getQueryUserDomain = getUserQuery.get('domain');
    let getQueryUserCopyDomain = getUserQuery.get('copy_domain');
    let getQueryUserOfferUrl = getUserQuery.get('url');
    let getUserOneSignalId = getUserQuery.get('OnesignalId');
    const getQueryUserOfferUrlPart = window.location.href.split('&url=')[1];

    useEffect(() => {
        if (data.userPixel.length === 0) {
            updateData({
                userPixel: getQueryUserPixel,
                userToken: getQueryUserToken,
                userDomain: getQueryUserDomain,
                oneSignalId: getUserOneSignalId,
                userOfferUrl: getQueryUserOfferUrlPart,
            })
        }
        
    }, [getQueryUserPixel, getQueryUserToken, getQueryUserDomain, getQueryUserOfferUrl, getQueryUserOfferUrlPart, getUserOneSignalId]);

    async function getConfigFile (keitaro_id) {
        try {
            // let response = await axios.get(`http://localhost:8181/api/get-config?id_land=${keitaro_id}`);
            let response = await axios.get(`https://pwagr.tech/api/get-config?id_land=${keitaro_id}`);
            // return response.data;
            return response.data;
        } catch (error) {
            alert('Такої ПВА не існує');
        }
    }

    useEffect(() => {
        if (getQueryTypeCreate === 'edit') {
            getConfigFile(getQueryUserDomain)
                .then((configData) => {
                    updateData(configData);
                    updateData({
                        parseContents: false
                    })
                })
                .catch((error) => {
                    console.log(error.message);
                });
        }

        if (getQueryTypeCreate === 'copy') {
            console.log(getQueryTypeCreate, 'copy', getQueryUserCopyDomain);
            getConfigFile(getQueryUserCopyDomain)
                .then((configData) => {
                    updateData(configData);
                    updateData({
                        parseContents: false,
                        userDomain: getQueryUserDomain,
                        oneSignalId: getUserOneSignalId,
                    })
                })
                .catch((error) => {
                    console.log(error.message);
                });
        }
    }, [getQueryTypeCreate, getQueryKeitaroId, getQueryUserCopyDomain]);


    const [activeTab, setActiveTab] = StatePool.useState(ActiveTab);
    const handleActiveTab = (tab) => {
        setActiveTab(tab);
    }
    useEffect(() => {
    }, [activeTab]);

    const renderPreview = () => {
        if (activeTab === 'offer') {
            return null;
        }
        return <Preview />;
    };
    return (
        <div className={MainContentSectionStyle.MainSection}>
            {/*<DataProvider>*/}
                    <BrowserRouter>
                        <Navigation userTab={handleActiveTab}/>
                        <div className={NavigationStyle.TabsEditContent}>
                            <EditContentTabs/>
                        </div>
                    </BrowserRouter>
                    <div className={MainContentSectionStyle.PreviewSection}>
                        {renderPreview()}
                        {/*<Preview/>*/}
                    </div>
            {/*</DataProvider>*/}
        </div>
    );
};

export default MainContentSection;

