import React, {useEffect, useState} from 'react';
import { useData } from "../../../Context/ContextApp";
import '../PWAPreviewStyle.css';
import StarBlue from "../img/icon/icon-full-big-blue.png";
import StarGreen from "../img/icon/star-full-big-green.png";
import StarGray from "../img/icon/star-full-big.png";
import TranslateText from "../Translate/text-translate.json";

const RatingSection = () => {
    const { data } = useData();
    const progressBars = data.progressBars;
    const ListTextSecurity = TranslateText[data.langCode];
    const [pwaDesign, setPwaDesign] = useState(data.pwaDesign);

    useEffect(() => {
        setPwaDesign(data.pwaDesign)
    }, [data.appRatingPage, data.pwaDesign]);

    return (
        <div className="rating_block">
            <div className="rating_block__title" id="rating-title">
                {data.langCode.length > 0 ? ListTextSecurity['rating-title'] : 'Rating and reviews'}
            </div>
            <div className="rating_block__content">
                <div className="rating_block__number-rating">
                    <p className="rating_block__number" id="number-rating">{data.appRatingPage === '' ? '4.7' : data.appRatingPage}</p>
                    <p className="rating_block__star-list">
                        <span className="rating_block__star"><img src={pwaDesign ? StarGreen : StarBlue} alt="" /></span>
                        <span className="rating_block__star"><img src={pwaDesign ? StarGreen : StarBlue} alt="" /></span>
                        <span className="rating_block__star"><img src={pwaDesign ? StarGreen : StarBlue} alt="" /></span>
                        <span className="rating_block__star"><img src={pwaDesign ? StarGreen : StarBlue} alt="" /></span>
                        <span className="rating_block__star"><img src={pwaDesign ? StarGreen : StarBlue} alt="" /></span>
                    </p>

                    <p className="rating_block__count-reviews">{data.countComments === '' ? '5463' : data.countComments}</p>
                </div>
                <div className="rating_block__chart-list">
                    {[5, 4, 3, 2, 1].map((rating, index) => (
                        <div key={rating} className="rating_block__chart-item">
                            <span>{rating}</span>
                            <div className="rating_block__chart-bar">
                                <progress value={progressBars[index] / 100} max="1"/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default RatingSection;
