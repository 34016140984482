import './assets/style/AppStyle.css';
import {Header, MainContentSection} from "./imports/imports-components";
import React from 'react';
import initIi18n from './locales/i18n';
import {DataProvider} from './components/Context/ContextApp';
import {ErrorContextProvider} from "./components/ErrorHandle/ErrorContext";
import ErrorPopup from "./components/ErrorHandle/ErrorHandle";

function App() {
    initIi18n();

    return (
        <div className="MainFullScreen">
            <DataProvider>
                <ErrorContextProvider>
                    <ErrorPopup/>
                    <Header/>
                    <MainContentSection/>
                </ErrorContextProvider>
            </DataProvider>
        </div>
    );
}

export default App;
