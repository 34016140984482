import {React, useEffect, useState} from '../../imports/imports-packege';
import ErrorPopupStyle from './ErrorPopupStyle.module.scss';
import {useErrorContext} from "./ErrorContext";

const ErrorPopup = () => {
    const {error, setError} = useErrorContext();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!error.message) {
            setIsVisible(false);
            return;
        }

        setIsVisible(true);

        const timerId = setTimeout(() => {
            setIsVisible(false);
        }, 3000);

        return () => {
            clearTimeout(timerId);
        };
    }, [error]);

    return (
        <div className={`${ErrorPopupStyle.ErrorPopup} ${isVisible && ErrorPopupStyle.visible}`}>
            <p className={ErrorPopupStyle.Text}>{error.message}</p>
        </div>
    );
};

export default ErrorPopup;