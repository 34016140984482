import {React, useState, useEffect, useTranslation} from "../../../imports/imports-packege";
import {ActionTitle, SelectList, Input, Button} from "../../../imports/imports-components";
import {UploadIconNew, SuccessUpload, HoverIconIcon} from "../../../imports/imports-images";
import {useData} from "../../Context/ContextApp";
import AppDesignStyle from "./AppDesignStyle.module.scss";
import {ListOptionLanguageEnVersion} from "../../SelectOption/SelectOption";
import axios from 'axios';
import '../../../assets/style/AppStyle.css';
import {useErrorContext} from "../../ErrorHandle/ErrorContext";

const INITIAL_REVIEWS_COUNT = 4;

const AppDesign = () => {
    const {t} = useTranslation();
    const {data, updateData} = useData();
    const {error} = useErrorContext();

    const [appName, setAppName] = useState('');
    const [previewUrl, setPreviewUrl] = useState(null);
    const [iconUploadStatus, setIconUploadStatus] = useState(false);
    const [iconBaseCode, setIconBaseCode] = useState('');
    const [previewScreens, setPreviewScreens] = useState([null, null, null, null]);
    const [screenshotUploadStatus, setScreenshotUploadStatus] = useState([false, false, false, false]);
    const [selectedTranslate, setSelectedTranslate] = useState(data.langCode || '');
    const [landId, setLandId] = useState(data.userDomain);
    const [LinkParse, setLinkParse] = useState('');
    const [isFirstLoadCompolete, setIsFirstLoadCompolete] = useState(false);

    const [iconHover, setIconHover] = useState(false);
    const [screenHover, setScreenHover] = useState([false, false, false, false]);

    useEffect(() => {
        setLandId(data.userDomain);
        console.log(landId, 'landId');
    }, [data.userDomain])

    const handlerAppName = (query_appName) => {
        setAppName(query_appName);
        updateData({
            ...data,
            appName: query_appName,
        });
    };

    useEffect(() => {
        setAppName(data.appName || '');
    }, [data.appName]);

    useEffect(() => {
        updateData({...data, langCode: selectedTranslate});
    }, [selectedTranslate, data.keitaroId]);

    useEffect(() => {
        if (!isFirstLoadCompolete) {
            setIsFirstLoadCompolete(true);
        }
    }, []);

    const handleIconUrl = (event) => {
        const file = event.target.files[0];
        if (file) {
            const previewIcon = new FileReader();
            previewIcon.onload = () => {
                setPreviewUrl(previewIcon.result);
                setIconUploadStatus(true);
            };
            previewIcon.readAsDataURL(file);
            handleFileChange(file);
        }
    };

    const handleFileChange = (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            const fileObject = new File([file], 'icon_resize.png', {type: file.type});
            setIconBaseCode(base64String);
            updateData({
                ...data,
                iconUrl: base64String,
                iconFile: fileObject,
            });
        };
        reader.readAsDataURL(file);
    };

    const handleScreenChange = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                const updatedScreens = [...previewScreens];
                updatedScreens[index] = imageUrl;
                setPreviewScreens(updatedScreens);

                const updatedStatus = [...screenshotUploadStatus];
                updatedStatus[index] = true;
                setScreenshotUploadStatus(updatedStatus);

                const fileObject = new File([file], file.name, {type: file.type});
                updateData({
                    ...data,
                    [`screenList${index + 1}`]: fileObject,
                    screenshotImages: {
                        ...data.screenshotImages,
                        [`screenList${index + 1}`]: imageUrl,
                    },
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelectTranslate = (translate) => {
        setSelectedTranslate(translate);
    };
    const sendLinkParse = async () => {
        // let url = 'http://localhost:8181/api/parse-googleplay?link_parse=';
        let url = 'https://pwagr.tech/api/parse-googleplay?link_parse=';
        if (!LinkParse) {
            alert(t('alerts.addSendLink'));
            return;
        }
        await axios.get(`${url}${LinkParse}&land_id=${landId}`)
            .then((response) => {
                if (response.data.error) {
                    alert(t('alerts.sendLinkError'));
                    return false;
                }
                let iconLink = response.data.icon;
                let screensLink = response.data.screen;
                updateData({...data, iconUrl: iconLink, screenshotImages: screensLink, parseContents: true});
                HideInputLoadImages();
            });
    };

    const HideInputLoadImages = () => {
        let iconBlock = document.getElementById('icon_block');
        let screenBlock = document.getElementById('screen_block');
        iconBlock.style.display = 'none';
        screenBlock.style.display = 'none';
    };

    const ShowInputLoadImages = () => {
        let iconBlock = document.getElementById('icon_block');
        let screenBlock = document.getElementById('screen_block');
        iconBlock.style.display = 'block';
        screenBlock.style.display = 'block';
    };

    useEffect(() => {
        if (data.parseContents) {
            HideInputLoadImages();
        } else {
            ShowInputLoadImages();
        }
    }, [data.parseContents]);

    const handleMouseEnterIcon = () => setIconHover(true);
    const handleMouseLeaveIcon = () => setIconHover(false);

    const handleMouseEnterScreen = (index) => {
        setScreenHover(prevState => {
            const updatedStatus = [...prevState];
            updatedStatus[index] = true;
            return updatedStatus;
        });
    };

    const handleMouseLeaveScreen = (index) => {
        setScreenHover(prevState => {
            const updatedStatus = [...prevState];
            updatedStatus[index] = false;
            return updatedStatus;
        });
    };

    return (
        <div className={AppDesignStyle.TabContent}>
            <div className={AppDesignStyle.Section}>
                <ActionTitle actionText={t('app.name.title')}/>
                <Input
                    className={AppDesignStyle.InputField}
                    inputValue={handlerAppName}
                    placeholder={t('app.name.placeholder')}
                    valueSave={appName}
                    error={error.place === 'appName'}
                />
            </div>
            <div className={`${AppDesignStyle.Section} ${AppDesignStyle.SelectList}`}>
                <ActionTitle actionText={t('app.lang.title')}/>
                <SelectList
                    optionList={ListOptionLanguageEnVersion}
                    getSelectValue={handleSelectTranslate}
                    value={selectedTranslate}
                    error={error.place === 'langCode'}
                />
            </div>

            <div className={AppDesignStyle.Section}>
                <h2 className={AppDesignStyle.CustomTitle}>{t('app.design.title')}</h2>
                <Input
                    inputValue={setLinkParse}
                    valueSave={LinkParse}
                    placeholder={t('app.design.placeholder')}
                />
                <div onClick={sendLinkParse}>
                    <Button ButtonText={t('common.copy')}/>
                </div>
            </div>

            <div className={`${AppDesignStyle.Section}`} id="icon_block">
                <ActionTitle actionText={t('app.icon.title')}/>
                <div className={AppDesignStyle.SelectIconBlock}>
                    <div
                        className={`${AppDesignStyle.DownloadIconApp} ${iconUploadStatus ? AppDesignStyle.upload_success : ''} ${error.place === 'iconUrl' && AppDesignStyle.error}`}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleIconUrl}
                            onMouseEnter={handleMouseEnterIcon}
                            onMouseLeave={handleMouseLeaveIcon}
                            className={AppDesignStyle.DownloadIconApp_IconButtonDownload}
                        />
                        {iconUploadStatus ? (
                            <img src={SuccessUpload}
                                 className={`${AppDesignStyle.DownloadIconApp_Icon} ${AppDesignStyle.DownloadIconApp_Icon_success}`}
                                 alt="Success Upload Icon"/>
                        ) : (
                            <>
                                <img src={iconHover ? HoverIconIcon : UploadIconNew}
                                     className={AppDesignStyle.DownloadIconApp_Icon}
                                     alt="Upload Icon"
                                />
                                <p className={AppDesignStyle.DownloadIconApp_TextBlock}>
                                    <span
                                        className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>{t('app.icon.placeholder.1')}</span>
                                    <span
                                        className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>{t('app.icon.placeholder.2')}</span>
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className={`${AppDesignStyle.Section}`} id="screen_block">
                <ActionTitle actionText={t('app.screenshots.title')}/>
                <div className={AppDesignStyle.ScreenshotList}>
                    {[0, 1, 2, 3].map(index => (
                        <div className={AppDesignStyle.ContainerItem} key={index}>
                            <div
                                className={`${AppDesignStyle.DownloadIconApp} ${AppDesignStyle.ScreenItem} ${screenshotUploadStatus[index] ? AppDesignStyle.upload_success : ''}`}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => handleScreenChange(event, index)}
                                    onMouseEnter={() => handleMouseEnterScreen(index)}
                                    onMouseLeave={() => handleMouseLeaveScreen(index)}
                                    className={AppDesignStyle.DownloadMultiScreen_MultiImageButtonDownload}
                                />
                                {screenshotUploadStatus[index] ? (
                                    <img src={SuccessUpload}
                                         className={`${AppDesignStyle.DownloadIconApp_Icon} ${AppDesignStyle.DownloadIconApp_Icon_success}`}
                                         alt="Success Upload Icon"/>
                                ) : (
                                    <>
                                        <img src={screenHover[index] ? HoverIconIcon : UploadIconNew}
                                             className={AppDesignStyle.DownloadIconApp_Icon} alt="Upload File Icon"/>
                                        <p className={AppDesignStyle.DownloadIconApp_TextBlock}>
                                            <span
                                                className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>{t('app.screenshots.placeholder.1')}</span>
                                            <span
                                                className={AppDesignStyle.DownloadIconApp_TextBlock_Span}>{t('app.screenshots.placeholder.2')}</span>
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AppDesign;
