import {React, createContext, useContext, useState} from "../../imports/imports-packege";

const ContextApp = createContext();

export const DataProvider = ({children}) => {
    const [data, setData] = useState({
        pwaDesign: false,
        userPixel: '',
        userToken: '',
        userDomain: '',
        userOfferUrl: '',
        previewPage: '',
        appRatingPage: '',
        keitaroId: '',
        oneSignalId: '',
        TemplateReviewStatus: true,
        landName: '',
        langCode: '',
        iconUrl: '',
        iconFile: [],
        parseContents: false,
        screenshotImages: [],
        author: '',
        categories: '',
        countComments: '',
        templateReviewCheck: true,
        age: '',
        appSize: '',
        amountInstall: '',
        buttonText: '',
        landDescriptionTitle: '',
        landDescriptionText: '',
        review: '',
        countReviewShow: '',
        langReviewSelect: '',
        templateReview: [],
        formDataScreen: [],
        screenListFirst: [],
        screenListSecond: [],
        screenListThird: [],
        screenListFour: [],
        query_domain_user: '',
        reviews_data: [],
        multiCategories: [],
        recommendedApp: [],
        progressBars: [0, 0, 0, 0, 0]
    });
    const updateData = (newData) => {
        setData((prevState) => ({ ...prevState, ...newData }));
    }

    return (
        <ContextApp.Provider value={{ data, updateData }}>
            {children}
        </ContextApp.Provider>
    );
}

export const useData = () => {
    return useContext(ContextApp)
}


