import {React, useEffect, useState, useTranslation} from "../../../imports/imports-packege";
import AppParametersStyle from "./AppParametersStyle.module.scss";
import {ActionTitle, Input, SelectList, TextAria} from "../../../imports/imports-components";
import {AppCategoriesEnVersion, AppListUserAge, AppRecommendedIcon} from "../../SelectOption/SelectOption";
import {useData} from "../../Context/ContextApp";
import Select from 'react-select';
import ProgressBar from "../../UI/ProgressBar/ProgressBar";
import {useErrorContext} from "../../ErrorHandle/ErrorContext";

const AppParameters = () => {
    const {t} = useTranslation();
    let {data, updateData} = useData();
    const {error} = useErrorContext();

    const [authorApp, setAuthorApp] = useState('');
    const handleAuthorApp = (author) => {
        setAuthorApp(author);
        updateData({...data, author: author});
    }

    useEffect(() => {
        handleAuthorApp(data.author)
    }, [data.author])

    const [userAge, setUserAge] = useState(data.age || '');
    useEffect(() => {
        updateData({...data, age: userAge});
    }, [userAge]);
    const handleUserAge = (translate) => {
        setUserAge(translate);
    }

    const [appRating, setAppRating] = useState(data.appRatingPage || '');

    const handleRatingApp = (rating) => {
        setAppRating(rating);
        updateData({...data, appRatingPage: rating});
    }

    const [appSize, setAppSize] = useState('');
    const handleAppSize = (size) => {
        setAppSize(size);
        updateData({...data, appSize: size});
    }
    useEffect(() => {
        handleAppSize(data.appSize);
    }, [data.appSize]);

    const [installCount, setInstallCount] = useState('');
    const handleInstallCount = (count) => {
        setInstallCount(count);
        updateData({...data, amountInstall: count});
    }
    useEffect(() => {
        handleInstallCount(data.amountInstall)
    }, [data.amountInstall]);

    const [landDescriptionTitle, setLandDescriptionTitle] = useState('');
    const handleDescriptionTitle = (descriptionTitle) => {
        setLandDescriptionTitle(descriptionTitle);
        updateData({...data, landDescriptionTitle: descriptionTitle});
    }
    useEffect(() => {
        handleDescriptionTitle(data.landDescriptionTitle)
    }, [data.landDescriptionTitle]);


    const [countComments, setCountComments] = useState('');
    const handleCountComments = (count) => {
        setCountComments(count);
        updateData({...data, countComments: count});
    }
    useEffect(() => {
        handleCountComments(data.countComments)
    }, [data.countComments]);

    const [landDescription, setLandDescription] = useState('');
    const handleDescription = (description) => {
        setLandDescription(description);
        updateData({...data, landDescriptionText: description});
    }
    useEffect(() => {
        handleDescription(data.landDescriptionText);
    }, [data.landDescriptionText]);

    const [appMultiCategories, setAppMultiCategories] = useState(data.multiCategories || []);
    const handleDataUpdate = () => {
        updateData({...data, multiCategories: appMultiCategories});
    };
    useEffect(() => {
        if (appMultiCategories !== data.multiCategories) {
            handleDataUpdate();
        }
    }, [appMultiCategories, data.multiCategories, updateData]);

    // useEffect(() => {
    //     if (data.categories_list) {
    //         let listCategories = data.categories_list.split(',');
    //         setAppMultiCategories(listCategories);
    //     }
    // }, [data.categories_list]);

    const handleAppMultiCategories = (selectedOptions) => {
        const categoriesValues = selectedOptions.map(option => option.value);
        setAppMultiCategories(categoriesValues);
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            padding: 10,
            display: 'flex',
            alignItems: 'center',
        }),
    };

    const IconOption = ({data, innerProps}) => (
        <div {...innerProps} className="icon-option">
            <img src={data.icon} alt="" style={{marginRight: 10, width: 24, height: 24}}/>
            {data.label}
        </div>
    );

    const [recommendedApp, setRecommendedApp] = useState(data.recommendedApp || []);
    const handleDataAppUpdate = () => {
        if (recommendedApp !== data.recommendedApp) {
            updateData({...data, recommendedApp: recommendedApp});
        }
    }
    useEffect(() => {
        if (recommendedApp !== data.recommendedApp) {
            handleDataAppUpdate();
        }
    }, [recommendedApp]);

    const handleRecommendedApp = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setRecommendedApp(selectedValues);
    };

    useEffect(() => {
        if (data.recommended_app) {
            let listSelectApp = data.recommended_app.split(',');
            setRecommendedApp(listSelectApp);
        }
    }, [data.recommended_app]);

    return (
        <div className={AppParametersStyle.TabContent}>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={t('parameters.author.title')}/>
                <Input
                    placeholder={t('parameters.author.placeholder')}
                    inputValue={handleAuthorApp}
                    valueSave={authorApp}
                    error={error.place === 'author'}
                />

            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={t('parameters.countComments.title')}/>
                <Input
                    placeholder={t('parameters.countComments.placeholder')}
                    inputValue={handleCountComments}
                    valueSave={countComments}
                    error={error.place === 'countComments'}
                />
            </div>
            <div className={`${AppParametersStyle.ParametersItem} ${AppParametersStyle.DoubleSelect}`}>
                <div className={AppParametersStyle.List}>
                    <ActionTitle actionText={t('parameters.age.title')}/>
                    <SelectList
                        optionList={AppListUserAge}
                        getSelectValue={handleUserAge}
                        value={userAge}
                        error={error.place === 'age'}
                    />
                </div>
                <div className={AppParametersStyle.List}>
                    <ActionTitle actionText={t('parameters.size.title')}/>
                    <Input
                        placeholder={t('parameters.size.placeholder')}
                        inputValue={handleAppSize}
                        valueSave={appSize}
                        error={error.place === 'appSize'}
                    />
                </div>
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={t('parameters.installations.title')}/>
                <Input
                    placeholder={t('parameters.installations.placeholder')}
                    inputValue={handleInstallCount}
                    valueSave={installCount}
                    error={error.place === 'amountInstall'}
                />
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={t('parameters.landDescriptionTitle.title')}/>
                <Input
                    placeholder={t('parameters.landDescriptionTitle.placeholder')}
                    inputValue={handleDescriptionTitle}
                    valueSave={landDescriptionTitle}
                    error={error.place === 'landDescriptionTitle'}
                />
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={t('parameters.landDescriptionText.title')}/>
                <TextAria
                    placeholderText={t('parameters.landDescriptionText.placeholder')}
                    valueField={handleDescription}
                    valueSave={landDescription}
                    error={error.place === 'landDescriptionText'}
                />
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={t('parameters.rating.title')}/>
                <Input
                    placeholder={t('parameters.rating.placeholder')}
                    inputValue={handleRatingApp}
                    valueSave={appRating}
                />
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={t('parameters.ratingChart.title')}/>
                {[0, 1, 2, 3, 4].map((index) => (
                    <ProgressBar key={index} index={index}/>
                ))}
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={t('parameters.categories.title')}/>
                <Select
                    closeMenuOnSelect={false}
                    options={AppCategoriesEnVersion}
                    isMulti
                    onChange={handleAppMultiCategories}
                    value={appMultiCategories.map(category => ({value: category, label: category}))}
                    placeholder={`${t('common.select')}...`}
                />
            </div>

            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={t('parameters.recommended.title')}/>
                <Select
                    closeMenuOnSelect={false}
                    options={AppRecommendedIcon}
                    isMulti
                    value={recommendedApp && AppRecommendedIcon.filter(option => recommendedApp.includes(option.value))}
                    onChange={handleRecommendedApp}
                    components={{Option: IconOption}}
                    placeholder={`${t('common.select')}...`}
                    styles={customStyles}
                />
            </div>
        </div>
    );
};

export default AppParameters;